import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
    name: 'auth',
    initialState: { 
        user: null, 
        token: null, 
        authBearer: null,
        viewType: false,
        isAuthenticated: false,
        baseUrl: null
    },
    reducers: {
        setLogin: (state, action) => {
            const { user, token ,auth,viewType } = action.payload;
            state.user = user
            state.token = token
            state.authBearer = auth
            state.viewType = viewType ==='true'?true:false
        },
        setBaseUrl: (state, {payload})=>{
            state.baseUrl = payload
        }, 
        logOut: (state, action) => {
            state.user = null
            state.token = null
        },
        setViewType: (state, {payload})=>{
            state.viewType= payload.viewType
        }
    },
})
export const { setLogin, logOut, setBaseUrl } = authSlice.actions;
export default authSlice.reducer;

export const selectCurrentUser = (state)=>state.auth.user;
export const selectCurrentToken = (state)=>state.auth.token;
