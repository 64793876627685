import { Button, Menu, Table, Layout, theme, Flex, Modal, message } from 'antd';
// import { Header } from 'antd/es/layout/layout';
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { setLogin } from '../features/auth/authSlice';
import { CopyTwoTone, DeleteTwoTone, EditTwoTone, PlusOutlined } from '@ant-design/icons';
import { useDeleteEmailTemplateMutation, useDuplicateEmailTemplateMutation, useGetAllEmailTemplatesQuery, useLazyGetAllEmailTemplatesQuery, useLazyGetSiteInfoQuery } from '../features/mailer/mailerApiSlice';
import moment from 'moment/moment';
import LoadingComponentAntD from '../components/loadingCompAntd/LoadingComponentAntD';
import { setSiteInfor } from '../store/slice/siteInfoSlice';
import { Input, Space } from 'antd';

const {  Content } = Layout;

const { Search } = Input;
// const topOptions = [
//     {
//         label: 'topLeft',
//         value: 'topLeft',
//     },
//     {
//         label: 'topCenter',
//         value: 'topCenter',
//     },
//     {
//         label: 'topRight',
//         value: 'topRight',
//     },
//     {
//         label: 'none',
//         value: 'none',
//     },
// ];
// const bottomOptions = [
//     {
//         label: 'bottomLeft',
//         value: 'bottomLeft',
//     },
//     {
//         label: 'bottomCenter',
//         value: 'bottomCenter',
//     },
//     {
//         label: 'bottomRight',
//         value: 'bottomRight',
//     },
//     {
//         label: 'none',
//         value: 'none',
//     },
// ];




function AllEmailTemplates() {
    const [getSiteInfo] = useLazyGetSiteInfoQuery();
  const dispatch = useDispatch();
//   const storeAuth = useSelector(state=>state.auth)
    const [headersReady, setHeadersReady] = useState(false);
    const { user_token, auth, viewType } = useParams();
    // const dispatch = useDispatch();
    const navigate = useNavigate();
    const storeAuth = useSelector(state=>state.auth)
    // console.log("storeAuth::",storeAuth)
    const [messageApi, contextHolder] = message.useMessage();
    // const user = useSelector(state => state.auth);
    const [dataList, setDataList] = useState([]);
    const [filterList, setFilterList] = useState([]);
    const [moduleName, setModuleName] = useState(null);
    // const [getAllEmailTemplates] = useLazyGetAllEmailTemplatesQuery();
    const [deleteEmailTemplate] = useDeleteEmailTemplateMutation();
    const [duplicateEmailTemplate] = useDuplicateEmailTemplateMutation();

    const storeBaseUrl = useSelector(state=>state.baseUrl);

    const [search, setSearch] = useState('');

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = clearFilters => {
        clearFilters();
        setSearchText('');
    };

    useEffect(() => {
        if (storeAuth?.authBearer && storeAuth?.token ) {
            // console.log("storeAuth",storeAuth)
            setHeadersReady(true);
        }
    }, [storeAuth,storeBaseUrl.value]);

    const {data, error} = useGetAllEmailTemplatesQuery(undefined,{
        skip: !headersReady, // Skip the query until headers are ready
    });

    // const [getSiteInfo] = useLazyGetSiteInfoQuery();

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [loading, setLoading] = useState(true);

    // useEffect(() => {
    //     const fetchSiteInfo = async()=>{
    //         try {
    //             if (data) {
    //                 console.log("useGetAllEmailTemplatesQuery-data::",data);
                    
    //             } 
    //             if (error) {
    //                 console.log("useGetAllEmailTemplatesQuery-error::",error);

    //             }
    //         } catch (err) {
    //             console.log("catch-err::",err)
    //         }

    //     }
    //     if (user_token && auth) {
    //         fetchSiteInfo();
    //     }
    // }, [data,error  ])


   
    

    useEffect(() => {
        if (user_token && auth && viewType) {
            dispatch(setLogin({
                user: null,
                token: user_token,
                auth,
                viewType
            }))
        }
    }, [user_token, auth,viewType]);

    useEffect(() => {
        if (user_token && auth) {
            const fetchAllTemps = async()=>{

                try {
                    if (data) {
                        if (data?.status) {
                            const newData = data.templates.map((template,index)=>({...template,serial:index+1,created_at: moment(template.created_at).format('ll'),key:template.id }))
                            setDataList(newData);
                            setFilterList(newData);
                            setModuleName(data?.module_name);
                            setTimeout(() => {
                                setLoading(false);
                            }, 3000);
                            setLoading(false);
                        }
                        if(!data?.status){
                            console.log("!data.status")
                            // window.location.replace(data.url)
                            if (data?.message) {
                                errorMsg(data?.message)
                            }
                        }
                    }
                    if (error) {
                        console.log("error::",error)
                    }
                }    
                 catch (error) {
                    console.log("catch-error::",error)
                 }finally{
                    setTimeout(() => {
                        setLoading(false);
                    }, 3000);
                 }
                
            }
            fetchAllTemps();
        }
    }, [data, error])

    // const {
    //     token: { colorBgContainer, borderRadiusLG },
    // } = theme.useToken();
    useEffect(() => {
        const fetchSiteInfo = async()=>{
            try {
                const {data,error} = await getSiteInfo();
                if (data) {
                    // console.log("site-data::",data);
                    dispatch(setSiteInfor(data))
                    
                } 
                if (error) {
                    console.log("site-data::",error);
    
                }
            } catch (error) {
                
            }
    
        }
     
        if (!headersReady) {
          // console.log("if->siteInfo::storeAuth")
            fetchSiteInfo();
        }
    }, [getSiteInfo, storeAuth, headersReady])

    

    const start = () => {
        setLoading(true);
        // ajax request after empty completing
        setTimeout(() => {
            setSelectedRowKeys([]);
            setLoading(false);
        }, 2000);
    };
    useEffect(() => {
        start();
    }, [])

    const onSelectChange = (newSelectedRowKeys) => {
        // console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;

    const dataSource = [
        {
            key: '1',
            name: 'Mike',
            age: 32,
            address: '10 Downing Street',
        },
        {
            key: '2',
            name: 'John',
            age: 42,
            address: '10 Downing Street',
        },
    ];

    const columns = [
        {
            title: 'Sr#',
            dataIndex: 'serial',
            key: 'serial',
            width: '22%',
            align: 'center'
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            align: 'left',
        },
        {
            title: 'Created Date',
            dataIndex: 'created_at',
            key: 'created_at',
            align: 'left'
        },
        {
            title: 'Action',
            dataIndex: '',
            // fixed: 'right',
            width: '40%',
            key: 'x',
            align: 'center',
            render: (key) =>
                <Flex gap="middle" vertical>
                    <Flex wrap gap="middle" justify='center'>
                    <EditTwoTone onClick={() => navigateToUpdate(key.id)} />
                       <CopyTwoTone twoToneColor="#52c41a" onClick={()=> handleDuplicateTemp(key.id)} />
                        <DeleteTwoTone twoToneColor="#eb2f96" onClick={() => handleDelete(key.id)} />
                    </Flex>
                </Flex>,
            // </div> ,
        },
    ];
    
    
    
    const successMsg = (message) => {
        messageApi.open({
          type: 'success',
          content: message,
        });
      };
    
      const errorMsg = (message) => {
        messageApi.open({
          type: 'error',
          content: message,
        });
      };
  
  

    const handleDelete = (record) => {
        // console.log("deleteEmailTemplate",record)
        Modal.confirm({
            title: "Are you sure you want to delete this template?",
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            centered: true,
            keyboard: true,
            maskClosable: true,
            confirmLoading: true,
            onOk: async () => {
                try {
                    // Simulate API call delay
                    const {data,error} = await deleteEmailTemplate(record);
                    if (data) {
                        // console.log("data",data)
                        successMsg(data?.message);

                    }
                    if (error) {
                        console.log("error",error)
                        errorMsg(error?.data?.message);
                    }
                } catch (error) {
                    // If there's an error, catch it and log it
                    console.error('Oops errors!', error);
                }
            },
        });
    };

    const handleDuplicateTemp = (tempID)=>{
        Modal.confirm({
            title: "Are you sure you want to duplicate this template?",
            okText: 'Yes',
            okType: 'primary',
            cancelText: 'No',
            centered: true,
            keyboard: true,
            maskClosable: true,
            confirmLoading: true,
            onOk: async () => {
                try {
                    const {data,error} = await duplicateEmailTemplate(tempID);
                    if (data) {
                        // console.log("data",data)
                        successMsg(data?.message);

                    }
                    if (error) {
                        console.log("error",error)
                        errorMsg(error?.data?.message);
                    }
                } catch (error) {
                    // If there's an error, catch it and log it
                    console.error('Oops errors!', error);
                }
            },
        });
    }

    const navigateCreate = () => {
        navigate(`/${auth}/${user_token}/${viewType}/add`);
    }

    const navigateToUpdate = (id) => {
        navigate(`/${auth}/${user_token}/${viewType}/edit/${id}`);
    }

    const onChange = (value, _e, info) => setSearch(value.target.value);

    useEffect(() => {
        const result =dataList.filter(temp=>{
            return temp?.name.toLowerCase().match(search.toLowerCase())
        })
        setFilterList(result);
      }, [search, dataList]);

    return (
        <>
        {loading && 
            <LoadingComponentAntD content={'Loading'}/>
        }  
        {contextHolder}
            <Content style={{ padding: '0 48px' }}>
              
                <div className='container'

                >
                    
                    <div className='table-enter-leave-demo-action-bar'>
                        <Flex gap="middle" vertical>
                            <Flex wrap gap="middle" justify='end'> 
                            {moduleName &&
                            <>
                                <Button type="primary" onClick={navigateCreate}><PlusOutlined/>Create</Button>
                            </>
                            }
                            </Flex>
                        </Flex>

                    </div>
                    <div className="table-enter-leave-demo-action-bar">
                        <Flex gap="middle" vertical>
                            <Flex wrap gap="middle" justify='end'> 
                            
                            {moduleName &&
                            <> 
                                <Search placeholder="search by name" onChange={onChange} style={{ width: 300 }} />
                            </>
                            }
                            </Flex>
                        </Flex>
                   </div>
                
                    <Table
                        //  rowSelection={rowSelection}
                        pagination={{
                            position: ['none', 'bottomCenter'],
                            // pageSize: 10,
                            // total: 50, showSizeChanger: true ,
                            // pageSizeOptions: ["20", "25", "30"]
                            pageSizeOptions: ["10", "20", "25", "30"],
                            showSizeChanger: true,
                            locale: { items_per_page: "" }
                        }}
                        loading={loading}
                        bordered={true}
                        scroll={{
                            y: 400,
                        }}
                        className="custom-table-class"
                        virtual={true} //boolean
                        //   size='large'
                        columns={columns}
                        // columns={columns(searchText, setSearchText, searchedColumn, setSearchedColumn, searchInput)} 
                        dataSource={filterList} />
                </div>
            </Content>


            {/* <Table dataSource={dataSource} columns={columns} /> */}

        </>
    )
}

export default AllEmailTemplates



 // return new Promise((resolve, reject) => {
                //     setTimeout(Math.random() > 0.5 ? resolve : reject, 2000);
                //   }).catch(() => console.log('Oops errors!'));

                // try {
                //     await handleSubmitDelete();
                //     // If the API call is successful, resolve the promise
                //     return Promise.resolve();
                // } catch (error) {
                //     // If there's an error, catch it and log it
                //     console.error('Oops errors!', error);
                //     // Reject the promise
                //     return Promise.reject();
                // }