import apiSlice from "../../store/apis/apiSlice";


export const mailerApiSlice = apiSlice.injectEndpoints({
    TAGS: ["MAIL"],

    endpoints: builder => ({

        getAllEmailTemplates: builder.query({
            query: () => ({
                url: '/fetch-all-email-templates',
                method: 'GET'
            }),
            providesTags: ['MAIL']
        }),

        saveEmailTemplate: builder.mutation({
            query: (data) => ({
                url: `/add-email-template`,
                method: "POST",
                body: { ...data }
            }),
            invalidatesTags: ['MAIL']
        }),
        getSingleEmailTemplate: builder.query({
            query: (id) => ({
                url: `/get-single-email-template/${id}`,
                method: "GET",
            })
        }),

        updateEmailTemplate: builder.mutation({
            query: ({ id, body }) => {
                // console.log(id, "Payload",body)
                return {
                    url: `/update-email-template/${id}`,
                    method: "POST",
                    body
                }
            },
            invalidatesTags: ['MAIL'],
        }),
        deleteEmailTemplate: builder.mutation({
            query: id => {
                return {
                    url: `/delete-email-template/${id}`,
                    method: 'DELETE'
                }
            },
            invalidatesTags: ['MAIL']
        }),
        
        uploadImage: builder.mutation({
            query: (body)=>({
                url:`/upload-image-for-email-template`,
                method:'POST',
                body
            })
        }),

        getSiteInfo: builder.query({
            query: ()=> '/fetch-site-info'
        }),
        duplicateEmailTemplate: builder.mutation({
            query: id=>({
                url:`/duplicate-email-template/${id}`,
                method: 'POST',

            }),
            invalidatesTags: ['MAIL']
        }),

       
    })
})

export const {
    useSaveEmailTemplateMutation,
    useLazyGetSingleEmailTemplateQuery,
    useUpdateEmailTemplateMutation,
    useUploadImageMutation,
    useLazyGetAllEmailTemplatesQuery,
    useDeleteEmailTemplateMutation,
    useLazyGetSiteInfoQuery,
    useGetAllEmailTemplatesQuery,
    useDuplicateEmailTemplateMutation,


} = mailerApiSlice;